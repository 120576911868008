<template>
    <div class="login-page">登录</div>
</template>

<script>
export default {
    name: "Login",
};
</script>

<style>
</style>